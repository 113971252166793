.wrapper{
    width: 80%;
}

.wrapper h6{
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #D0D0D0;
    margin: 0;
}

.cardsWrapper{
    margin-top: 70px;
}




