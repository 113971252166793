.chartContainer {
  /* display: flex;
  flex-direction: column; */
  height: 490px;
  width: 100%;
  padding: 2rem 3rem;
  /* background-color: rgba(89, 186, 193, 0.1); */
  position: relative;
}

.borderContainer{
  width: 100%;
  padding-left: 15px;
  padding-right: 40px;
}

.chartBtns{
  margin-top: 20px;
  width: 100%;
}

.legendContainer{
  background: #023C5A; 
  border-radius: 4px;
  width: 13px;
  height: 13px;
}

.legendWrapper{
  color: #383838;
  font-size: 16px;
  font-weight: 500;
}

