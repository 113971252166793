.wrapper {
  height: calc(100vh - 150px);
  padding: 50px 30px 70px 30px;
  border-right: 1px solid #d0d0d0;
}

.reduceHeight {
  height: 75vh;
}

.logoWrapper {
  gap: 20px;
}

.logoWrapper img {
  width: 65px;
}

.logoWrapper h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #0077b5;
}

.menuWrapper {
  margin-top: 0px;
  gap: 20px;
}

.menuWrapper svg {
  width: 30px;
}

.subMenuLinks {
  padding: 0 0 0 50px;
}

.subMenuLinks h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: #000000;
}

.activeSubMenu {
  color: #0077b5 !important;
}

.avatarImage {
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.avatarContainer {
  margin-left: 20px;
}

.userNameText {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #000000;
}

.companyNameText {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-transform: capitalize;
  color: #0077b5;
}

.avatarContainer {
  position: relative;
}

.logoutConatiner {
  right: 40px;
  bottom: 10px;
  position: absolute;
  background: #f9f9f9;
  border-radius: 15px;
  width: 160px;
  height: 69px;
  padding: 0 20px;
  gap: 8px;
}

.logout,
.darkMode {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.1em;
  color: #000000;
}

.logout {
  color: #bd3437;
}

.iconBox {
  width: 25px;
}
