.chartContainer {
  display: flex;
  flex-direction: column;
  height: 680px;
  width: 100%;
  padding: 2rem 3rem;
  /* background-color: rgba(89, 186, 193, 0.1); */
  position: relative;
}

.chartBtns{
  /* position: absolute; */
  /* left: 35vw; */
  margin-top: 20px;
  width: 100%;
  /* z-index: 100; */
}

.borderContainer{
  width: 100%;
  padding-left: 15px;
  padding-right: 40px;
}

.bottomBorder{
  background: rgba(0, 0, 0, 0.5);
  height: 1px;
  width: 100%;
}

.btnStyle {
  border-radius: 20px;
  color: #0077B5;
  width: 56px;
  height: 30px;
};

.chartBtnsDiv {
  height: 60px;
  width: 100%;
  padding: 2rem 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.btn {
  display: grid;
  place-items: center;
  cursor: pointer;
  /* padding-top: 0.3rem; */
  font-weight: 600;
  border-radius: 30px;
}


.btnDiv, .btnHover:hover{
  background-color: #0077B5 !important;
  border-color:  #0077B5 !important;
  color: white !important;
}

.lengendText{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.3px;
  color: #000000;
  text-transform: capitalize;
  margin-left: 10px;
}

.chartBtnsLeft {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: -0.3px;
  text-transform: capitalize;
  color: #000000;
}

.chartBtnsRight {
  display: flex;
  gap: 1rem;
}

.yAxisContainer{
  position: absolute;
  right: 8vw;
  bottom: 0px;
  height: 500px;
  /* background-color: #0077B5; */
  /* padding: 239px 0; */
}

.yAxisContainer .pill{
  background: #FFFFFF;
  border-radius: 300px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.3px;
  color: rgba(0, 0, 0, 0.8);
  height: 25px;
  padding: 0 10px;
  min-width: 100px;
}

.peakContainer{
  position: relative;
  margin-bottom: 125px;
}

.peakText{
  position: absolute;
  left: 9vw;
  bottom: 4px;
  width: 100px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #0077B5;
}



/* MEDIA QUERIES */
@media only screen and (max-width: 1300px) {
  /* .chartBtnsDiv {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
    padding: 2rem 1rem;
  } */

  .chartBtnsRight {
    flex-wrap: wrap;
    justify-content: center;
    /* flex-direction: row; */
    /* justify-content: flex-start; */
    margin-top: 1rem;
  }

  .chartContainer {
    padding: 1rem 0;
  }

  .chartInner {
    padding: 1rem !important;
  }
}

@media (max-width: 480px) {
  .btnStyle {
    width: 45px;
  };

  .chartBtnsRight, .chartBtnsRight {
    flex-direction: row;
    justify-content: center;
  }

  .chartBtnsRight, .chartBtnsLeft {
    gap: 0.2rem;
  }
}
