.wrapper{
    border: 1.5px solid rgba(208,208,208, 0.5);
    border-radius: 31px;
    width: 300px;
    height: 243px;
}

.wrapper h4{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    margin-top: 20px;
}