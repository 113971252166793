.wrapper{
    background: #01486D;
    min-height: 100vh;
}

.cardWrapper{
    width: 530px;
    height: 681px;
    background: #FFFFFF;
    border-radius: 31px;
    padding: 30px;
    gap: 20px;
}