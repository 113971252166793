.wrapper{
    padding: 5px 5px;
    border: 0.75px #D0D0D0 solid;
    background: #f9f9f9;
}
.wrapper h6{
    color: black;
    font-size: 13.05px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 12.56px;
    letter-spacing: 1px;
  }

.wrapper p{
    font-family: 'Lato';
    color: #0077B5;
    font-size: 13.05px;
    font-weight: 600;
    line-height: 12.56px;
}