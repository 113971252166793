

.tab{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    padding: 5px 15px;
}

.activeTab{
    color: #0077B5;
    background: #E5F1F7;
    border-radius: 42.5px;
}

.descWrapper{
    overflow-y: scroll;
    height: 280px;
    padding: 0 20px;
}

.descWrapper p{
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #000000;
}

.descWrapper h5{
    font-size: 16px;
}

.titleText{
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #000000;
}

.reviewLaterText{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    color: #000000;
}

.reviewLaterText span{
    color: #0077B5;
}

.logoWrapper{
    gap: 20px;
}

.logoWrapper h3{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #0077B5;
}

.agreementsContainer{
    border: 1.5px solid #F3F3F3;
    border-radius: 31px;
    height: 361px;
    width: 100%;
}

@media (max-width: 480px) {
    .logoWrapper h3{
        font-size: 16px;
        line-height: 20px;
    }

    .logoWrapper img{
        width: 40px;
    }
    .tab{
        font-size: 12px;
        line-height: 13px;
        padding: 5px 10px;
    }

    .descWrapper h5{
        font-size: 12px;
        line-height: 15px;
    }

    .descWrapper p{
        font-size: 11px;
        line-height: 18px;
    }

    .titleText{
        font-size: 23px;
        line-height: 32px;;
    }
}