.wrapper{
    padding: 0 50px 0 20px;
}

.wrapper h3{
    color: black;
    font-size: 30px;
    font-weight: 700;
}

.wrapper h4{
    color: black;
    font-size: 23px;
    font-weight: 700;
}

.wrapper p{
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
}

.wrapper h5{
    color: black;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
}

.searchContainer{
    background: #F9F9F9; 
    border-radius: 26.50px;
    padding: 6px 15px;
}

.searchContainer input{
    background: none;
    border: none;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 400;
}

.searchContainer input:focus {
    outline: none;
}

.searchContainer input::placeholder {
    color: #ABABAB;
}

.filterButton{
    color: black;
    font-size: 16px;
    font-weight: 500;
    border-radius: 26.50px; 
    border: 0.50px black solid;
    padding: 6px 15px;
}

.filterCard{
    position: absolute;
    right: 0px;
    top: 40px;
    width: 240px; 
    height: 155px; 
    background: white; 
    border-radius: 20px; 
    border: 0.50px black solid;
    z-index: 100;
}

.filterCard .header{
    border-bottom: 0.50px #D0D0D0 solid;
    padding-top: 8px;
    margin-bottom: 10px;
}

.filterOption{
    color: black;
    font-size: 14.5px;
    font-weight: 500;
    padding: 0 5px;
}

.activeTab{
    border-bottom: 2px #000000 solid;
}

.typeText{
    color: black;
    font-size: 14.5px;
    font-weight: 500;
    padding: 0 10px;
}

.typeText input[type=checkbox]{
    visibility:hidden;
    -webkit-appearance: none;
}

.typeText input[type='checkbox']:after {
    width: 13px;
    height: 13px;
    position: relative;
    background-color: #F9F9F9;
    border: 1.5px solid #D0D0D0;
    border-radius: 15px;
    content: '';
    margin-top: 5px;
    margin-right: 10px;
    display: inline-block;
    visibility: visible;
}

.typeText input[type='checkbox']:checked:after {
    width: 13px;
    height: 13px;
    position: relative;
    background: #0077B5;
    border: 1.5px solid #0077B5;
    border-radius: 15px;
    content: '';
    margin-top: 5px;
    margin-right: 10px;
    display: inline-block;
    visibility: visible;
}

.amountText{
    color: black;
    font-size: 20px;
    font-weight: 500;
}

.notificationContainer{
    padding: 20px 0;
    margin: 30px 0;
    border-bottom: 1.5px solid #D0D0D0;
}

.notificationContainer img{
    width: 40px;
}

.reportLink{
    color: #0077B5;
    font-size: 20px;
    font-family: Lato;
    font-weight: 500;
    text-decoration: underline;
}
