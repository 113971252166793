.wrapper{
    width: 60%;
}

.topSection h3{
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    color: #000000;
}

.tableDiv {
    height: max-content;
    width: 100%;
    overflow-x: auto;
}
.tableDiv table {
    height: max-content;
    width: 100%;
}

.tableDiv table tr th {
    padding: 1rem 0;
    width: max-content;
}

.tableDiv table tr td {
    padding: 1rem 0;
    width: max-content;
    border-top: 1px solid #ececec;
    box-sizing: border-box;
}

.dotIndicator{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #F1A03D;
    margin-right: 10px;
}

.failedIndicator{
    background: #BD3437;
}

.paidIndicator{
    background: #5DBE94;
}