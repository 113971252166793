.wrapper{
    gap: 20px;
}

.titleText{
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    text-align: center;
    color: #000000;
}

.subText, .backButton{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
}

.backButton{
    text-decoration: underline;
}