.titleText{
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    align-items: center;
    text-align: center;
}

.infoWrapper{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    gap: 20px
}

.listContainer svg{
    width: 25px;
    margin:10px 0;
}