.button__bar {
	display: inline-block;
	vertical-align: middle;
	margin: auto;
	padding: 0;
	// background: rgba(255, 255, 255, 0.3);
	border-radius: 40px;
	text-align: center;
	li {
		list-style: none;
		cursor: pointer;
		display: inline-block;
		margin: 0 3px;
		padding: 0;
		button {
			padding: 0;
			border: none;
			background: #F9F9F9;
			color: transparent;
			cursor: pointer;
			display: block;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			&:hover {
				background: #59BAC1;
				outline: 0;
			}
			&:focus {
				background: #59BAC1;
				outline: 0;
			}
		}
	}
	li.slick-active {
		button {
			background-color: #59BAC1;
		}
	}
}