.wrapper h3{
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    display: flex;
    align-items: center;
    color: #000000;
}

.cardsWrapper{
    gap: 60px;
}