.wrapper{
    width: 80%;
}

.wrapper h6{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: #0077B5;
}

.articleTitle{
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    padding: 20px 0;
    border-bottom: 1px solid rgba(182, 182, 182, 0.23);
}

.articleWrapper{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
}

.articleWrapper h3{
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 42px;
    color: #0077B5;
}

.articleWrapper ol li{
    margin: 10px 0;
}

