.mailIconContainer{
    width: 152px;
    height: 152px;
    border-radius: 500px;
    background: #01486D;
    flex-shrink: 0;
}

.headingText h3{
    font-size: 28px;
    font-weight: 700;
    color: black;
}

.headingText p{ 
    font-size: 16px;
    font-weight: 500;
    color: #C4C4C4;
}

.warningContainer{
    width: 100%;
    background-color: rgba(0, 119, 181, 0.1);
    border-radius: 12px;
    padding-top: 20px;
    height: 80px;
}

.warningContainer p{
    font-weight: 400;
    letter-spacing: 0em;
    color: #01486D;
}

.numberInputContainer{
    width: 100%;
}

.numberInputContainer input{
    border: 2px solid rgba(208, 208, 208, 0.5);
    border-radius: 8px;
    Width: 63.42px;
    Height: 70.45px
}

.numberInputContainer input{
    font-size: 40px;
    text-align: center;
}

.footer h6{
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #01486D;
}

.dividerLine{
    height: 2px;
    width: 100%;
    background-color: rgba(208, 208, 208, 0.5);
    flex-shrink: 0;
    margin: 20px 0 10px 0;
}

.footer p{
    color: #C4C4C4;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
}

.footer p a{
    color: #0077B5;
    text-decoration: none;
}


