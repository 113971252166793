.wrapper{
    width: 471px;
    height: 233px;
    border: 1.5px solid #D0D0D0;
    border-radius: 31px;
    gap: 20px;
}

.wrapper h4{
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 30px;
    text-align: center;
    color: #000000;
}

.descText{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #D0D0D0;
    text-align: center;
}

.buttonsWrapper{
    margin-top: 3px;
    gap: 20px;
}

.buttonsWrapper button{
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    background: #0077B5;
    border-radius: 18.5px;
    border: none;
    padding: 5px 20px;
}
