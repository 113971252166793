.topSection h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.3px;
  text-transform: capitalize;
  color: #000000;
}

.dropDownField,
.dropDownFieldDownload {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  z-index: 1;
  color: #000000;
  border: 1.5px solid rgba(208, 208, 208, 0.5);
  border-radius: 31px;
  padding: 5px 10px;
  width: 100%;
  margin: 10px 0;
}

.dropDownField {
  /* position: absolute; */
  padding: 5px 3px;
}

.dropDownFieldDownload {
  margin: 0;
  padding-top: 7px;
  padding-bottom: 7px;
}

.dropDownOption {
  border-radius: 20.5px;
  padding: 5px 10px;
}

.bridgeDropDown,
.sourceDropDown {
  background: #ffffff;
  border-radius: 20px;
}

.bridgeDropDown {
  z-index: 2;
}

.sourceDropDown {
  z-index: 1;
}

.selectedOption {
  padding: 0px 3px 0px 8px;
  /* gap: 20px; */
}

.optionsWrapper,
.periodOption,
.downloadOptionsWrapper {
  position: relative;
  height: 40px;
  margin: 10px 0;
  width: 300px;
}

.periodOption {
  width: 120px;
}

.downloadOptionsWrapper {
  width: 160px;
  z-index: 200;
}

.shareLinkContainer .labelText {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-top: 10px;
  margin-right: 20px;
}

.copyInput {
  background: #f4f4f4;
  border-radius: 31px;
  padding-left: 10px;
}

.copyInput input {
  border: none;
  background-color: transparent;
  color: #d0d0d0;
}

.iconWrapper {
  width: 20px;
}

.liveButton {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1.7px;
  color: #ffffff;
  background: #0077b5;
  border-radius: 20px;
  padding: 3px 15px;
}

.statisticsCard {
  /* background: #E5F1F7; */
  border-radius: 31px;
  height: 119px;
  margin: 50px 0;
  /* opacity: 0.2; */
}

.statisticContainer {
  border-right: 1px solid #c5bebe;
  min-width: 170px;
  padding: 20px 60px;
}

.noboader {
  border: none;
}

.statisticContainer h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 28px;
  letter-spacing: -0.3px;
  color: #000000;
}

.statisticContainer h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.3px;
  /* color: #0077B5; */
}

.tabsWrapper {
  gap: 30px;
}

.tabText {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.3px;
  color: #d0d0d0;
  padding: 20px 0;
  gap: 13px;
  height: 80px;
}

.selectedItem {
  position: relative;
  color: black;
  font-family: "Lato";
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 3px;
  border-bottom: 1px solid #000000;
  margin-left: 10px;
}

.selectOption,
.selectProfitOption {
  color: black;
  font-family: "Lato";
  font-size: 15px;
  border-radius: 12px;
  width: 90px;
  padding: 5px 3px 5px 13px;
  top: 35px;
  left: 60px;
  background: #f9f9f9;
  position: absolute;
  box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(12.5px);
}

.selectProfitOption {
  top: 35px;
  left: 90px;
}

.profitPercentage {
  color: #0077b5;
  font-size: 15px;
  font-weight: 400;
  border-radius: 20px;
  padding: 3px 6px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.activeTab,
.activeChartTab h3,
.activeChartTab h5,
.activeChartTab .selectedItem {
  color: #0077b5;
  /* border-bottom: 3px solid #0077B5; */
  border-color: #0077b5;
}

.underLineText {
  /* width: 100%; */
  height: 4px;
  background: #0077b5;
  border-radius: 20px;
}

.buttonsWrapper,
.buttonsLinksWrapper {
  gap: 30px;
}

.mtLogin {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #0077b5;
  border: 1.5px solid #0077b5;
  border-radius: 31px;
  padding: 5px 10px;
}
.downloadButton {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  border: 1.5px solid #d0d0d0;
  border-radius: 31px;
  padding: 5px 15px;
  background: transparent;
}

.mt5Login,
.shareLinkContainer {
  position: absolute;
  width: 511px;
  height: 404px;
  left: 40%;
  top: 20%;
  background: #ffffff;
  border: 1.5px solid #d0d0d0;
  border-radius: 20px;
  padding: 20px;
}

.shareLinkContainer {
  height: 304px;
}

.mt5Login h4,
.shareLinkContainer h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}

.shareLinkContainer button {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #0077b5;
  border: 1.5px solid #0077b5;
  border-radius: 31px;
  padding: 6px 15px;
}

.mt5Login .credentials {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  gap: 20px;
}

.checkboxContainer input[type="checkbox"] {
  visibility: hidden;
  -webkit-appearance: none;
}

.checkboxContainer input[type="checkbox"]:after {
  width: 20px;
  height: 20px;
  /* border-radius: 15px; */
  position: relative;
  background-color: transparent;
  border: 1.5px solid rgba(208, 208, 208, 0.5);
  border-radius: 5px;
  content: "";
  margin-top: 10px;
  display: inline-block;
  visibility: visible;
}

.checkboxContainer input[type="checkbox"]:checked:after {
  width: 20px;
  height: 20px;
  /* border-radius: 15px; */
  position: relative;
  background: #0077b5;
  border: 1.5px solid #0077b5;
  border-radius: 5px;
  content: "";
  display: inline-block;
  visibility: visible;
}

.checkboxContainer span {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: #000000;
  margin-left: 10px;
}

.mt5Login .credentials .hiddenPassword {
  color: #e5f1f7;
  font-size: 20px;
}

.statisticsCardDisabled {
  opacity: 0.2;
}

.statisticsCardDisabled:hover {
  opacity: 1;
}

.statisticContainer:hover > h3,
.statisticContainer:hover > div h5 {
  color: #01486d;
}

.statisticContainer:hover .selectedItem {
  color: #01486d;
  border-color: #01486d;
}

.mt5Login button {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  width: 172px;
  height: 44px;
  border: 1.5px solid rgba(208, 208, 208, 0.5);
  border-radius: 100px;
  background: transparent;
}

.buttonsLinksWrapper a {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  width: 172px;
  height: 44px;
  border: 1.5px solid rgba(208, 208, 208, 0.5);
  border-radius: 100px;
  background: transparent;
  text-decoration: none;
}

.copyInput .copiedLinkButton {
  background: #01486d;
  border-color: #01486d;
}

.infoPopup {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #000000;
  position: absolute;
  width: 337px;
  height: 69px;
  top: 28px;
  right: 15px;
  background: #f9f9f9;
  border-radius: 6px;
  padding: 0 10px;
}

.infoIconWrapper {
  margin-top: -10px;
  margin-left: 5px;
}
