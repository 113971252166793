.contextMenu {
    width: 100%;
    /* font-family: Arial, sans-serif; */
    position: relative;
  }
  
  .selectItemButton {
    padding: 10px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;
    color: #666;
    /* border: 1px solid #ccc; */
    background-color: #f5f5f5;
    /* text-align: center; */
  
    border-radius: 100px; /* Fully rounded corners by default */
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    transition: border-radius 0.2s ease;
  }
  
  .selectItemButton.open {
    border-radius: 20px 20px 0 0;
  }
  
  .menu {
    list-style: none;
    padding: 0 0 8px 0;
    margin: 0;
    background: #f5f5f5;
    /* border: 1px solid #ccc; */
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 0 0 20px 20px;
    z-index: 1;
    /* overflow: hidden; */
  }

  .menu li{
    position: relative;
  }
  
  .menuButton {
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 4px 16px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #666;
    cursor: pointer;
    
  }

  button:disabled{
    color: #9f9f9f;
  }
  
  .submenu {
    list-style: none;
    padding: 4px 0 8px 0;
    margin: 0;
    background: #f0f0f0;
    position: absolute;
    left: 102%; /* Position submenu to the right of the parent */
    top: 0;
    width: 150px;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
  }
  
  .submenu li, .submenu h6{
    padding: 4px 8px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #666;
    cursor: pointer;
    white-space: nowrap;
  }

  .submenu h6{
    margin: 0;
    /* font-weight: 600; */
    color: #111;
  }
  
  /* .submenu li:hover {
    background: #ddd;
  } */
  
  /* .menuButton:hover, .submenu li:active {
    background-color: #007bff;
    color: white;
  } */