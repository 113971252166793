.content {
  height: 100vh;
  /* border-left: 1px solid #D0D0D0; */
  padding: 0px 40px 120px 40px;
  overflow-y: scroll;
}

.menu {
  /* border-right: 1px solid #d0d0d0; */
  height: 100vh;
}

.header {
  height: 140px;
}

.logoWrapper {
  gap: 20px;
  height: 150px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
}

.logoWrapper img {
  width: 65px;
}

.logoWrapper h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #0077b5;
}

.addPadding {
  padding-top: 40px;
}
