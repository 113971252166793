.wrapper h3{
    color: black;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
}

.wrapper p{
    color: black;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
}

.wrapper button{
    color: white;
    font-size: 17px;
    font-weight: 700;
    width: 100%;
    border: none;
    padding: 10px 0; 
    background: #0077B5; 
    border-radius: 10px
}

.wrapper button.changeButton{
    color: #01486D;
    background: none;
}

.wrapper button.disabledButton{
    background: #D0D0D0;
}

.wrapper img{
    height: 130px;
}

.selectorBox{
    width: 100%; 
    height: 220px; 
    background: transparent; 
    border-radius: 10px;
    border: 0.69px rgba(207.90, 207.90, 207.90, 0.50) solid;
    padding: 0 20px 0 30px;
}

.activeBox{
    border-color: #0077B5;
    background: rgba(0, 119, 181, 0.10);
}

.selectorBox h4{
    color: black;
    font-size: 16px;
    font-weight: 700;
}

.selectorBox p{
    font-size: 14px;
    line-height: 16px;
    text-align: left;
}

.selectorBox .footNote{
    color: #BD3437;
    font-size: 12px;
    font-weight: 400;
}

.resultBox{
    background: #E5F1F8; 
    border-radius: 10px;
    padding: 40px 10px
}

.resultBox h5{
    color: black;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
}

.resultBox p{
    color: black;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
}

.resultBox p span{
    font-weight: 700;
}


.circleCheckmark {
    background: transparent;
    border: 0.69px rgba(207.90, 207.90, 207.90, 0.50) solid;
    width: 20px;
    height: 20px;
    padding-left: 4px;
    margin-bottom: 10px;
    border-radius: 50%;
}

.circleChecked {
    background: #0077B5;
    border-color: #0077B5;

}