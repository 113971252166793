.timeContainer{
    border-radius: 8px;
    border: 1px #F0F0F0 solid;
    width: fit-content;
    padding: 10px 20px;
}

.imageContainer{
    height: 30px;
    width: 30px;
    border-radius: 100px;
    flex-shrink: 0;
}

.impactContainer{
    background: #F1FFEC; 
    border-radius: 8px;
    padding: 10px 20px;
    color: #50AD48;
    width: fit-content;
}

.impactHigh{
    color: #ED1717;
    background: #FFECEC;
}

.impactMedium{
    color: #AD6D48;
    background: #FFF3EC;
}

.calendarAddWrapper{
    position: relative;
}

.iconWrapper{
    padding: 30px;
}

.toolTipWrapper {
    position: absolute;
    display: none;
    color: white;
    font-size: 11px;
    font-weight: 400;
    background: #333333;
    padding: 5px 0;
    top: 70px;
    right: 70px;
    width: 115px;
    text-align: center;
    border-radius: 2px;
}

.toolTipWrapper:before {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    right: 10px;
    top: -18px;
    border: 10px solid transparent;
    border-right-color: #333333;
    transform: rotate(90deg);
  }

.iconAddWrapper:hover + .toolTipWrapper {
    display: block;
}

.desc{
    color: #757575;
    font-size: 14px;
    line-height: 24px;
    word-wrap: break-word 
}