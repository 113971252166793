.wrapper{
    border-top: 1px solid #D0D0D0;
    height: 60px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #FFFFFF;
    padding: 0 40px;
    gap: 40px;
}

.linkText a{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #0077B5;
    text-decoration: none;
}

.copyRight{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #C5BEBE;
}