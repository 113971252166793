.wrapper{
    padding: 0 50px 0 20px;
}

.wrapper h3{
    color: black;
    font-size: 30px;
    font-weight: 700;
}

.wrapper h4{
    color: black;
    font-size: 23px;
    font-weight: 700;
}

.wrapper p{
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
}

.wrapper h5{
    color: black;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
}

.refreshButton{
    background: rgba(0, 119, 181, 0.32); 
    border-radius: 100px;
    padding: 5px 10px;
    color: #0077B5;
    font-size: 16px;
    font-weight: 700;
}

.card{
    border-radius: 31px; 
    border: 1.50px #C5BEBE solid;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.card h1, .card h2{
    color: #383838;
    font-size: 92px;
    font-weight: 700;
    margin-top: 100px;
}

.card h2{
    color: #383838;
    font-size: 72px;
    margin: 0;
}

.card button{
    background: #0077B5; 
    border: none;
    padding: 5px 30px;
    border-radius: 31px;
    color: white;
    font-size: 20px;
    font-weight: 600;
    margin-top: 70px;
}

.bottomRow{
    height: 270px;
}

.statsWrapper{
    color: #383838;
    font-size: 17px;
    font-weight: 500;
    position: relative;
}

.statsWrapper span{
    color: #0077B5;
}

.infoCard{
    position: absolute;
    background: white; 
    box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.10);
    border-radius: 9px;
    border: 1px #C5BEBE solid;
    min-width: 100px;
    max-width: 300px;
    min-height: 40px;
    color: #383838;
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
    left: 0px;
    top: 25px;
    z-index: 102;
}

.loadingWrapper{
    height: 100%;
    width: 100%
}

.loadingWrapper p{
    color: #383838;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.loadingWrapper svg{
    width: 80px;
} 

.loader {
    width: 68px;
    height: 68px;
    border: 5px solid;
    border-color: #ABE2FF transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 


