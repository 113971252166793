.wrapper{
    width: 100%;
}

.infoCardWrapper {
    position: relative;
}

.infoCard{
    position: absolute;
    top: 2px;
    /* left: -120px; */
    background: #fff;
    transition: opacity 0.7s ease-out;
    opacity: 0;
    box-shadow: 0px 0px 14px rgba(154, 154, 154, 0.25);
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    width: 350px; 
    /* height: 150px; */
    visibility: hidden;
    z-index: 100;
}

.infoCard:before {
    content: "";
    position: absolute;
    top: -25px;
    left: 50px;
    /* left: 170px; */
    z-index: 1;
    border: solid 15px transparent;
    border-right-color: #fff;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

.wrapper h4{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #0077B5;
}

.wrapper h4:hover .infoCard{
    opacity: 1;
    visibility: visible;
}

.uploadCard{
    width: 100%;
    height: 71px;
    border: 1px dashed #D0D0D0;
}

.activeDrag{
    border-color: #0077B5;
}

.selectButton{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    align-items: center;
    color: #000000;
    padding: 0 10px;
    margin-left: 10px;
    border: 1.5px solid #D0D0D0;
    border-radius: 31px;
    background: none;
}

