.wrapper{
    gap: 20px;
}

.formWrapper input, .dropDownField{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border: 1.5px solid rgba(208,208,208, 0.5);
    border-radius: 31px;
    padding: 5px 10px;
    width: 100%;
    margin: 10px 0;
}

.dropDownField{
    position: absolute;
    padding: 5px 3px;
}

.dropDownOption{
    border-radius: 20.5px;
    padding: 5px 10px;
}

.dropDownOption:hover{
    background: rgba(217, 217, 217, 0.5);
}

.bridgeDropDown, .sourceDropDown{
    background: #FFFFFF;
    border-radius: 20px;
}

.bridgeDropDown{
    z-index: 2;
}

.sourceDropDown{
    z-index: 1;
}

.selectedOption{
    padding: 0px 10px;
}


.formWrapper input::placeholder{
    color: #D0D0D0;
}

.formWrapper img{
    height: 50px;
    margin-right: 5px;
}

.formWrapper{
    width: 100%;
}

.titleText{
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    color: #000000;
}

/* .menu_flags_button {
    color: white;
    width: 100%;
    border: none;
} */

.optionsWrapper{
    position: relative;
    height: 40px;
    margin: 10px 0;
}
