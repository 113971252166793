.wrapper{
    width: 60%;
}

.descText{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
}

.wrapper h5{
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 32px;
    color: #000000;
    margin-top: 30px;
}

.wrapper h4{
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-top: 50px;
}

.wrapper button, .bottomButtons .cancleButton{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    background: #0077B5;
    border-radius: 31px;
    padding: 8px 50px;
    border: none;
    margin-top: 50px;

}

.breadCrumbs h6, .breadCrumbs button{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    align-items: center;
    color: #0077B5;
}

.breadCrumbs h3{
    font-style: normal;
    font-weight: 700;
    font-size: 29px;
    line-height: 32px;
    align-items: center;
    color: #000000;
}

.breadCrumbs button{
    background: none;
    border: none;
}

.formWrapper input{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border: 1.5px solid rgba(208,208,208, 0.5);
    border-radius: 31px;
    padding: 5px 20px;
    width: 100%;
    margin: 10px 0;
    background: none;
}

.passwordWrapper{
    border: 1.5px solid rgba(208,208,208, 0.5);
    border-radius: 31px;
    margin: 10px 0;
    padding: 0;
}

.passwordWrapper input{
    border: none;
    /* width: 80%; */
    background: none;
    margin: 0;
}


.dropDownField{
    position: absolute;
    padding: 5px 3px;
}

.dropDownOption{
    border-radius: 20.5px;
    padding: 5px 10px;
}

.dropDownOption:hover{
    background: rgba(217, 217, 217, 0.5);
}

.bridgeDropDown, .sourceDropDown{
    background: #FFFFFF;
    border-radius: 20px;
}

.bridgeDropDown{
    z-index: 2;
}

.sourceDropDown{
    z-index: 1;
}

.selectedOption{
    padding: 0px 10px;
}


.formWrapper input::placeholder{
    color: #D0D0D0;
}

.formWrapper img{
    height: 50px;
    margin-right: 5px;
}

.formWrapper{
    width: 100%;
}

.titleText{
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    color: #000000;
}

/* .menu_flags_button {
    color: white;
    width: 100%;
    border: none;
} */

.optionsWrapper{
    position: relative;
    height: 40px;
    margin: 10px 0;
}

.radioLabel{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
}

.checkboxContainer input[type=checkbox]{
    visibility:hidden;
    -webkit-appearance: none;
}

.checkboxContainer input[type='checkbox']:after {
    width: 25px;
    height: 25px;
    /* border-radius: 15px; */
    position: relative;
    background-color: transparent;
    border: 1.5px solid rgba(208,208,208, 0.5);
    border-radius: 5px;
    content: '';
    margin-top: 10px;
    display: inline-block;
    visibility: visible;
}

.checkboxContainer input[type='checkbox']:checked:after {
    width: 25px;
    height: 25px;
    /* border-radius: 15px; */
    position: relative;
    background: #0077B5;
    border: 1.5px solid #0077B5;
    border-radius: 5px;
    content: '';
    display: inline-block;
    visibility: visible;
}

.bottomButtons{
    gap: 20px;
}

.bottomButtons .cancleButton{
    color: #000000;
    background: none;
}

.sendMember{
    width: 100%;
    height: 224px;
    background: rgba(208, 208, 208, 0.2);
    border-radius: 20px;
    margin-top: 50px;
    padding: 0 30px;
}

.sendMember .subText{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #141414;
    margin-right: 10px;
}

.sendMember h6{
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 20px;
    color: #0077B5;
}

.sendMember button{
    margin-top: 0;
    padding-left: 20px;
    padding-right: 20px;
}

.disabledButton{
    background: #D0D0D0 !important;
}

.errorContainer{
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    color: #000000;
    padding: 0 40px;
    position: absolute;
    top: 20px;
    left: 42%;
    width: 656px;
    height: 67px;
    background: #F8EBEB;
    border-radius: 50px;
    z-index: 101;
}

