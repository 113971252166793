.monthText{
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #000000;
}
  
  .percentageText{
    margin-top: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.3px;
    color: #5DBE94;
  }
  
  .innerStats{
    width: 30vw;
    margin: 20px 0;
  }