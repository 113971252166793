.numText{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    letter-spacing: -0.3px;
    color: #0077B5;
  }
  
  .statsText{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.3px;
    color: #000000;
    text-align: left;
  }
  
  .innerStats{
    position: relative;
    width: 20vw;
    margin: 20px 0;
  }

  .infoPopup{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #000000;
    position: absolute;
    text-align: center;
    width: 90%;
    height: auto;
    top: 20px;
    left: 0px;
    background: #F9F9F9;
    border-radius: 6px;
    padding: 10px;
    z-index: 200;
}

.infoPopup p a{
  color: #0077B5;
}

  @media (max-width: 768px) {
    .innerStats{
      width: 100%;
      margin: 20px 0;
    }
  }

