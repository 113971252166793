  .yearText{
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.3px;

    color: #59BAC1;
  }

  .monthText{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.3px;
    color: #000000;
    margin: 20px 0;
  }

  .percentageText{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.3px;
    color: #5DBE94;
    margin: 20px 0;
  }

  .vertical {
    border-left: 1px solid #D9D9D9;
    height: 460px;
    margin-top: 30px;
  }

  .totalText{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.3px;
    color: #000000;
  }

  .wrapper{
    padding: 0 10px;
  }