@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: "Lato", sans-serif !important;
}

.pagination {
  margin: 15px auto;
  display: flex;
  justify-content: end;
  list-style: none;
  outline: none;
  color: #0077b5;
}
.pagination > .active > a {
  background: #0077b5;
  border-color: #0077b5;
  color: #fff;
}
.pagination > li > a {
  border: 0.840647px solid #0077b5;
  border-radius: 3.36259px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 5px;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background: #0077b5;
  border: 0.840647px solid #0077b5;
  border-radius: 3.36259px;
  outline: none;
}
/* .pagination > li > a, .pagination > li > span{
  color: #0077B5 ;
} */
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
  display: none;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  border: none !important;
}

.dark-mode .main-page .MembersTable_topSection__g2Yzb button,
.dark-mode .main-page .Notifications_filterCard__BUf6m {
  border: 1.5px solid #ffffff;
}

/* Dark mode styling */

.dark-mode .main-page .main-divs div {
  background: #000a10 !important;
  color: white !important;
  transition: color 0.3s ease-in-out;
}

.dark-mode .main-page .ServicesFees_tableDiv__rLHPz table tr td {
  border-top: 1px solid #686d70;
}

.dark-mode .main-page .LinkContainer_active__PXqh1,
.dark-mode .main-page .FundsContainer_iconCircle__Z8a49,
.dark-mode .main-page .Funds_activeFundButton__EH1T2,
.dark-mode .main-page .HelpCenterBreadCrumbs_searchContainer__vakZ6,
.dark-mode .main-page .AddMember_sendMember__z8j9o {
  background: rgb(1, 63, 109, 0.5) !important;
}

.dark-mode .main-page .SideBar_logoutConatiner__HAECz {
  background: rgb(1, 63, 109) !important;
}

.dark-mode .main-page .AddMember_wrapper__vuqiZ .addbutton {
  background: #01293e;
}

.dark-mode .main-page .LinkContainer_wrapper__2KL8i h4,
.dark-mode .main-page .SideBar_subMenuLinks__T68rK h6,
.dark-mode .main-page .SideBar_userNameText__lT3Cn,
.dark-mode .main-page .Assets_statisticContainer__M1-Rf h3,
.dark-mode .main-page .Assets_topSection__EWIsr h2,
.dark-mode .main-page .Statistics_statTitle__hlHNn,
.dark-mode .main-page .Trading_cardContainer__MIZzg div h2,
.dark-mode .main-page .MethodContainer_methodCard__vY7Xt h3,
.dark-mode .main-page .FundsContainer_tableContainer__omsu- h3,
.dark-mode .main-page .FundsContainer_downloadButton__dBayH,
.dark-mode .main-page .FundsContainer_depositDetails__IAfu- h4,
.dark-mode .main-page .AddMember_formWrapper__H5-zQ input,
.dark-mode .main-page .AddMember_subText__PLqmL,
.dark-mode .main-page .AddMember_cancleButton__McULH,
.dark-mode .main-page .ServicesFees_topSection__s6sHb h3,
.dark-mode .Assets_checkboxContainer__AgFdt span,
.dark-mode .Assets_shareLinkContainer__Mxvo6 h4 {
  color: #ffffff !important;
}

.dark-mode .main-page .LinkContainer_active__PXqh1 h4,
.dark-mode
  .main-page
  .SideBar_subMenuLinks__T68rK
  .SideBar_activeSubMenu__b-sje,
.dark-mode .main-page .Assets_tabText__G88cw,
.dark-mode .main-page .ChartComponent_btn__p1F3e,
.dark-mode .main-page .StatsList_numText__QEaVy,
.dark-mode .main-page .Statistics_yearNum__TB7T-,
.dark-mode
  .main-page
  .HelpCenterBreadCrumbs_searchContainer__vakZ6
  input::placeholder,
.dark-mode .main-page .HelpCenterBreadCrumbs_prevLink__tD8sE,
.dark-mode .main-page .MethodContainer_selectItem__YxyzO,
.dark-mode .main-page .MethodContainer_valueAmount__udlMQ,
.dark-mode .main-page .ContactUs_dropDownOption__bfa5p {
  color: #0077b5 !important;
}

.dark-mode .main-page .IssueBanner_wrapper__86KFv,
.dark-mode .main-page .IssueBanner_infoText__2z2SR {
  background: #792529 !important;
}

.dark-mode .main-page .Layout_darkMode__XUt7b,
.dark-mode .main-page .Layout_iconBox__yEC1D,
.dark-mode .main-page .Layout_logout__ayWNS,
.dark-mode .main-page .Assets_statisticContainer__M1-Rf,
.dark-mode .main-page .input-group-append,
.dark-mode .main-page .AddMember_sendMember__z8j9o div,
.dark-mode .main-page .AddMember_cancleButton__McULH,
.dark-mode .main-page .main-divs .ChartComponent_yAxisContainer__R6rB4,
.dark-mode .main-page .main-divs .ChartComponent_yAxisContainer__R6rB4 div,
.dark-mode .main-page .main-divs .EquityChartComponent_yAxisContainer__liQzf,
.dark-mode .main-page .main-divs .EquityChartComponent_yAxisContainer__liQzf div {
  background: transparent !important;
}

.dark-mode .main-page .SideBar_darkMode__vIhcV {
  color: #fff;
}

.dark-mode .main-page .Layout_logout__ayWNS {
  color: #bd3437 !important;
}

.dark-mode .main-page .main-divs .Assets_liveButton__SyBND,
.dark-mode .main-page .main-divs .ChartComponent_btnDiv__BkD4s,
.dark-mode .main-page .main-divs .FundsContainer_liveButton__Do6HY,
.dark-mode .main-page .main-divs .MethodContainer_dot__JulZl,
.dark-mode .main-page .main-divs .MethodContainer_getCodeContainer__tH1iz {
  background: #0077b5 !important;
}

.dark-mode .main-page .main-divs .Assets_underLineText__7erL8 {
  background: #ffffff !important;
}

.dark-mode .main-page .main-divs .Assets_activeTab__Jivfh,
.dark-mode .main-page .main-divs .ChartComponent_lengendText__OTBcB,
.dark-mode .main-page .main-divs .Assets_downloadButton__23X\+v,
.dark-mode .main-page .main-divs .Assets_buttonsLinksWrapper__pbtqz a,
.dark-mode .main-page .main-divs .Assets_mt5Login__hZUqb h4,
.dark-mode .main-page .main-divs .Statistics_yearText__1R6I\+,
.dark-mode .main-page .main-divs .TradesCard_summaryText__HM1Do,
.dark-mode .main-page .main-divs .Statistics_summaryText__2kejd,
.dark-mode .main-page .main-divs .FundsContainer_topSection__GF0c2 h3,
.dark-mode .main-page .main-divs .FundsContainer_topSection__GF0c2 h6,
.dark-mode .main-page .main-divs .AddMember_breadCrumbs__OC9d8 h3,
.dark-mode .AddMember_wrapper__vuqiZ h5,
.dark-mode .main-page .main-divs .MembersTable_topSection__g2Yzb h3,
.dark-mode .main-page .main-divs .MembersTable_topSection__g2Yzb button,
.dark-mode .main-page .main-divs .HelpCenterBreadCrumbs_wrapper__I1xwi h3,
.dark-mode .main-page .main-divs .HelpCenter_wrapper__jZXmD h3,
.dark-mode .main-page .main-divs .HelpCard_wrapper__T-lSD h4,
.dark-mode .main-page .main-divs .MethodContainer_inputWrapper__lWynf input,
.dark-mode .main-page .main-divs .MethodContainer_points__P\+qC2 h6,
.dark-mode .main-page .main-divs .MethodContainer_howItWorksWrapper__ZFK6O h5,
.dark-mode
  .main-page
  .main-divs
  .MethodContainer_cryptoInputWrapper__S0ikv
  input,
.dark-mode
  .main-page
  .main-divs
  .MethodContainer_getCodeInputWrapper__dIoYu
  input,
.dark-mode
  .main-page
  .main-divs
  .MethodContainer_inputWireSendWrapper__PXW6T
  input,
.dark-mode
  .main-page
  .main-divs
  .MethodContainer_addressInputWrapper__zBpJs
  input,
.dark-mode .main-page .main-divs .ContactUs_wrapper__wBlEd textarea,
.dark-mode
  .main-page
  .main-divs
  .HelpCenterBreadCrumbs_searchContainer__vakZ6
  input,
.dark-mode .main-page .main-divs .recharts-default-tooltip ul li,
.dark-mode .main-page .main-divs .PreviewContainer_wrapper__YYgIG h5,
.dark-mode .main-page .main-divs .PreviewContainer_wrapper__YYgIG p,
.dark-mode
  .main-page
  .main-divs
  .MethodContainer_requestDetailsContainer__OLSft
  p,
.dark-mode .main-page .main-divs .Notifications_notificationContainer__eRbXY h5,
.dark-mode .main-page .main-divs .Notifications_wrapper__06I35 h3,
.dark-mode .main-page .main-divs .Notifications_wrapper__06I35 h4,
.dark-mode .main-page .main-divs .Notifications_wrapper__06I35 input,
.dark-mode .main-page .main-divs .gain-chart-tooltip-wrapper h6,
.dark-mode .main-page .main-divs .gain-chart-tooltip-wrapper p,
.dark-mode .main-page .main-divs .MethodContainer_selectItem__YxyzO {
  color: #ffffff !important;
}

.dark-mode
  .main-page
  .ChartComponent_btnHover__85ItH:hover
  .ChartComponent_btn__p1F3e,
.dark-mode
  .main-page
  .main-divs
  .ChartComponent_btnDiv__BkD4s
  .ChartComponent_btn__p1F3e,
.dark-mode .main-page .main-divs .AddMember_breadCrumbs__OC9d8 button,
.dark-mode .main-page .main-divs .PreviewContainer_wrapper__YYgIG div {
  background: transparent !important;
  color: #ffffff !important;
}

.dark-mode .main-page .main-divs .MonthlyGain_percentageText__JG4Cr {
  color: #5dbe94 !important;
}

.dark-mode .main-page .main-divs .TradesCard_underlineText__VA82S,
.dark-mode .main-page .main-divs .ServicesFees_dotIndicator__3o\+yz {
  background: #5dbe94 !important;
}

.dark-mode .main-page .main-divs .isNegativeNumber {
  color: #bd3437 !important;
}

.dark-mode .main-page .main-divs .isLoser,
.dark-mode .main-page .main-divs .ServicesFees_failedIndicator__oY3in {
  background: #bd3437 !important;
}

.dark-mode .main-page .main-divs .MethodContainer_disabledSubmit__pFjAN,
.dark-mode .main-page .main-divs div.Assets_infolconrapper__fvQ7d {
  background: #01293e !important;
}

.dark-mode .main-page .main-divs .MethodContainer_connectorLine__V\+uGQ {
  background: rgb(208, 208, 208, 0.7) !important;
}

.dark-mode .main-page .main-divs .MethodContainer_addressInput__TVKfC {
  background: rgb(0, 119, 181, 0.5) !important;
}

.dark-mode .main-page .main-divs .MethodContainer_disabledgetCode__BLoBR,
.dark-mode .main-page .main-divs .MethodContainer_codeInfoPopup__XWaNd,
.dark-mode .main-page .main-divs .Notifications_searchContainer__qnYL7 {
  background: #01293e !important;
}

.dark-mode .main-page .main-divs .AddMember_breadCrumbs__OC9d8 button {
  text-decoration: underline !important;
}

.dark-mode .main-page .main-divs .PreviewContainer_wrapper__YYgIG,
.dark-mode .main-page .main-divs .MethodContainer_editAddress__7xRpc,
.dark-mode .main-page .main-divs .Funds_bannerContainer__xuPNe,
.dark-mode .main-page .main-divs .MethodContainer_codeInfoPopup__xWaNd {
  background-color: #01293e !important;
}

.dark-mode .main-page .main-divs .pagination > li > a {
  border: 0.840647px solid #fff;
  color: #fff;
}

.dark-mode .main-page .main-divs .pagination > .active > a {
  background: #0077b5;
  border-color: #0077b5;
  color: #fff;
}

.dark-mode .main-page .main-divs .StatsList_statsText__rM3sf span {
  border-bottom: 1px dashed #fff !important;
}

.dark-mode .main-page .main-divs .Assets_statisticContainer__M1-Rf,
.dark-mode .main-page .main-divs .NewFeatures_contentArea__9\+f0J,
.dark-mode .main-page .main-divs .feature-icon-close-row,
.dark-mode .main-page .main-divs .NotificationsSlider_desc__EF9pV,
.dark-mode
  .main-page
  .main-divs
  .NotificationsSlider_leftSide__JYyWD
  .NotificationsSlider_bottomInner__B0zUz,
.dark-mode
  .main-page
  .main-divs
  .NotificationsSlider_leftSide__JYyWD
  .NotificationsSlider_topInner__ZMtY9,
.dark-mode .main-page .main-divs .NotificationsSlider_leftSide__JYyWD,
.dark-mode .main-page .main-divs .NotificationsSlider_cardWrapper__XrcT8 {
  background: transparent !important;
}

.dark-mode .main-page .main-divs .StatsList_infoPopup__RCsKi,
.dark-mode .main-page {
  background: #0b2e40 !important;
}

.dark-mode .main-page .main-divs .Assets_copyInput__xv1iq,
.dark-mode .main-page .main-divs .Assets_infoPopup__I3Bso {
  background: #011a27 !important;
}

.dark-mode .main-page .main-divs .Assets_selectedOption__MgUMi,
.dark-mode .main-page .main-divs .Assets_iconWrapper__C4lfn,
.dark-mode .main-page .main-divs .MethodContainer_editAddress__7xRpc div,
.dark-mode .main-page .main-divs .Notifications_header__ECcyc {
  background: none !important;
}

.dark-mode .main-page .main-divs .MethodContainer_editAddress__7xRpc:before {
  border-right-color: #01293e !important;
}

.dark-mode .main-page .main-divs .Assets_selectedItem__WU3Xq {
  border-color: #ffffff !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .recharts-surface{
  min-height: 500px !important;
} */

.recharts-surface .recharts-layer,
.dark-mode .main-page .main-divs div.PreviewContainer_infoPopup__CumbI,
.dark-mode .main-page .main-divs .LinkContainer_blueDot__3aHbu,
.dark-mode .main-page .main-divs .Assets_profitPercentage__cWJnb {
  background: #0077b5 !important;
}

.dark-mode
  .main-page
  .main-divs
  .MethodContainer_savedAddressContainer__V4bBD
  input,
.dark-mode
  .main-page
  .main-divs
  .MethodContainer_savedAddressContainer__V4bBD
  h4 {
  border-color: #686d70 !important;
  background: none !important;
  color: #ffffff !important;
}

.dark-mode .main-page .main-divs .Notifications_filterButton__Xhqze {
  border-color: #0077b5 !important;
  color: #0077b5 !important;
}

.dark-mode .main-page .main-divs .Notifications_activeTab__oYMQm {
  border-color: #ffffff !important;
}

.dark-mode .main-page .main-divs .Assets_activeChartTab__iR2CV h3,
.dark-mode .main-page .main-divs .Assets_activeChartTab__iR2CV h5,
.dark-mode .main-page .main-divs .Notifications_reportLink__Dc7qf,
.dark-mode .main-page .main-divs .NewFeatures_contentArea__9\+f0J h4,
.dark-mode .main-page .main-divs .NewFeatures_contentArea__9\+f0J h6,
.dark-mode .main-page .main-divs .NewFeatures_contentArea__9\+f0J p,
.dark-mode .main-page .main-divs .NotificationsSlider_desc__EF9pV h6,
.dark-mode .main-page .main-divs .NotificationsSlider_desc__EF9pV p {
  color: #ffffff !important;
}

.dark-mode
  .main-page
  .main-divs
  .Assets_statisticContainer__M1-Rf:hover
  .Assets_selectedItem__WU3Xq {
  color: #0077b5 !important;
  border-color: #0077b5 !important;
}

.dark-mode .main-page .main-divs .Assets_statisticContainer__M1-Rf:hover > h3,
.dark-mode
  .main-page
  .main-divs
  .Assets_statisticContainer__M1-Rf:hover
  > div
  h5 {
  color: #0077b5 !important;
}

.dark-mode
  .main-page
  .main-divs
  .ChartComponent_yAxisContainer__R6rB4
  .ChartComponent_pill__3Sumr,
.dark-mode
  .main-page
  .main-divs
  .EquityChartComponent_yAxisContainer__liQzf
  .EquityChartComponent_pill__MO0ub,
.dark-mode .main-page .main-divs .NewFeatures_iconWrapper__2LtcO,
.dark-mode .main-page .main-divs .NotificationsSlider_iconWrapper__cr3KJ {
  background: #0077b5 !important;
}

.dark-mode .main-page .main-divs .MethodContainer_horizontalDivider__\+\+SdM {
  background-color: #343c40 !important;
}

.dark-mode .main-page .main-divs .FundsContainer_statusDot__dim-5 {
  background-color: #d0d0d0 !important;
}

.dark-mode .main-page .main-divs .FundsContainer_greenDot__zJipr {
  background-color: #5dbe94 !important;
}

.dark-mode .main-page .main-divs .FundsContainer_redDot__BH1X1 {
  background-color: #bd3437 !important;
}

.dark-mode .main-page .main-divs .NewFeatures_wrapper__xAexe,
.dark-mode .main-page .main-divs .NotificationsSlider_notificationCard__0fxw- {
  border-color: #01293e !important;
  background: #01293e !important;
}

.dark-mode .main-page .main-divs .NotificationsSlider_leftSide__JYyWD,
.dark-mode .main-page .main-divs .NotificationsSlider_bottomInner__B0zUz {
  border-color: #343c40 !important;
}

.dark-mode .main-page .main-divs .StatsList_infoPopup_RCsKi a,
.dark-mode .main-page .main-divs .ClosePositionsModal_card__zpK\+k p,
.dark-mode .main-page .main-divs .GroupDetailsModal_tableDiv__Z1mMl table tr td,
.dark-mode .main-page .main-divs .DetailsModal_tableDiv__rnqDp table tr td,
.dark-mode .main-page .main-divs .Overview_card__dtKab h2,
.dark-mode .main-page .main-divs .Overview_card__dtKab h1,
.dark-mode .main-page .main-divs .Overview_wrapper__DOWUC h3 {
  color: #ffffff !important;
}

.dark-mode .main-page .main-divs .ClosePositionsModal_wrapper__VFjMo {
  background: rgba(0, 0, 0, 0.65) !important;
}

.dark-mode .main-page .main-divs .ClosePositionsModal_card__zpK\+k,
.dark-mode .main-page .main-divs .ClosePositionsModal_card__zpK\+k div {
  background: #01293e !important;
}

.dark-mode .main-page .main-divs .DetailsModal_upgradeModal__7ShIc,
.dark-mode .main-page .main-divs .GroupDetailsModal_upgradeModal__PHe9N {
  background-color: rgba(0, 0, 0, 0.65) !important;
}

.dark-mode .main-page .main-divs .Overview_refreshButton__mCyq8,
.dark-mode .main-page .main-divs .GroupDetailsModal_groupDropDown__kzS0s {
  background: rgba(0, 119, 181, 0.32) !important;
  color: #0077b5 !important;
}

.dark-mode .main-page .main-divs .GroupDetailsModal_groupDropDown__kzS0s div {
  background: transparent !important;
  color: #0077b5 !important;
}

.dark-mode .main-page .PercentButton_wrapper__P1M2i{
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
}

.dark-mode .main-page .PercentButton_active__RjkEs{
  border: 1px solid #0077B5;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
