.titleText{
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    text-align: center;
    color: #000000;
}

.descText{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #000000;
}

.descText a{
    text-decoration: underline;
    color: #000000;
}