.wrapper{
    gap: 10px;
    border-radius: 42.5px;
    width: 80%;
    height: 40px;
    padding: 0 15px;
}

.wrapper h4{
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    color: #000000;
    padding: 0;
    margin: 0;
}

.active{
    background: #E5F1F7; 
}

.active h4{
    color: #0077B5;
}

.blueDot{
    width: 10px; 
    height: 10px; 
    background: #0077B5; 
    border-radius: 100px;
    flex: none;
}
