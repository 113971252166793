.wrapper{
    border-radius: 500px;
    border: 1px solid #0077B5;
    color: #0077B5;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    width: 50px;
    padding: 2px;
}

.active{
    background: #0077B5;
    color: #FFFFFF;
}