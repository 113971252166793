.wrapper{
    padding: 10px;
    box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.10); 
    border-radius: 9px; 
    border: 1px #C5BEBE solid;
    background: white; 
}

.wrapper h6{
    color: #383838;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 500;
    margin: 0;
}

/* .wrapper p{
    font-family: 'Lato';
    color: #BD3437;
    font-size: 13.05px;
    font-weight: 600;
    line-height: 12.56px;
} */