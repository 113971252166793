.wrapper{
    height: 65px;
    width: 100%;
    background: #BD3437;
    padding: 0 30px;
    position: absolute;
    z-index: 100;
}

.infoText{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF;
    gap: 15px;
}

.wrapper button{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #BD3437;
    background: #FFFFFF;
    border-radius: 42.5px;
    border: none;
    padding: 5px 30px;
}